import React, {Fragment} from 'react'
import Helmet from 'react-helmet'
import {graphql, StaticQuery} from 'gatsby'
import {MDXProvider} from '@mdx-js/react'

import '../styles/styles.scss'
import Footer from './Footer'

import {MDXGlobalComponents, MDXLayoutComponents} from './mdx'

export default ({children, title, keywords, description, previewImage, path}) => {
  return (
    <StaticQuery query={layoutQuery}
                 render={({img, site}) => {
                   const {
                     siteUrl,
                     description: siteDescription,
                     title: siteTitle,
                     keywords: siteKeywords
                   } = site.siteMetadata
                   const joinedKeywords = (keywords || siteKeywords).join(', ')
                   const metaDescription = description || siteDescription
                   const titleTemplate = title === siteTitle ? `%s` : `%s | ${siteTitle}`
                   const imgSrc = previewImage || img.childImageSharp.fixed.src
                   const imgUrl = siteUrl + imgSrc
                   const twitterMeta = [
                     {name: 'twitter:creator', content: site.siteMetadata.author},
                     {name: 'twitter:title', content: title},
                     {name: 'twitter:description', content: metaDescription},
                     {name: 'twitter:image', content: imgUrl},
                     {name: 'twitter:card', content: 'summary_large_image'}
                   ]
                   const ogMeta = [
                     {property: 'og:title', content: title},
                     {property: 'og:description', content: metaDescription},
                     {property: 'og:type', content: 'website'},
                     {property: 'og:image', content: siteUrl + imgSrc}
                   ]
                   const url = siteUrl + path
                   const links = path ? [
                     {rel: 'canonical', href: url, key: url}
                    ] :[]
                   return (<Fragment>
                       <Helmet
                         title={title}
                         titleTemplate={titleTemplate}
                         meta={[
                           {name: 'description', content: metaDescription},
                           {name: 'keywords', content: joinedKeywords},
                           {
                             name: 'viewport',
                             content: 'width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0, maximum-scale=5.0'
                           },
                           {name: 'p:domain_verify', content: '7d9f58f85d2f5e577c9358b3a4c59bbf'},
                           ...twitterMeta,
                           ...ogMeta
                         ]}
                         link={links}
                       >
                         <html lang="en"/>
                       </Helmet>

                       <MDXProvider
                         components={{
                           ...MDXLayoutComponents,
                           ...MDXGlobalComponents
                         }}
                       >
                         <Fragment>
                           {children}
                           <Footer/>
                         </Fragment>
                       </MDXProvider>
                     </Fragment>
                   )
                 }}
    />
  )
};

const layoutQuery = graphql`
  query LayoutQuery {
    site {
      ...site
    }
    img: file(relativePath: { eq: "mockup_new.jpg" }) {
      childImageSharp {
        fixed(width: 1200, quality: 100) {
          ...GatsbyImageSharpFixed_noBase64
        }
      }
    }
  }
`
