function _decrypt(s) {
  let n = 0
  let r = ''
  for (let i = 0; i < s.length; i++) {
    n = s.charCodeAt(i)
    if (n >= 8364) {
      n = 128
    }
    r += String.fromCharCode(n - 1)
  }
  return r
}

export function decryptMailTo(e) {
  e.preventDefault()
  window.location.href = _decrypt('nbjmup;dpoubduAtdfofmbc/jp')
}
