import React from 'react'

import './Burger.scss'

export default ({onClick}) => {
  return (
    <div onClick={onClick} className="burger-menu">
      <div className="burger"/>
    </div>
  )
}
