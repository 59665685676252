import React from 'react'

import styles from './Video.module.scss'

export default ({src, type, caption}) => {
  return (
    <figure className={'video ' + styles.wrapper}>
      <video autoPlay loop>
        <source src={src} type={type} />
      </video>
      <figcaption>{caption}</figcaption>
    </figure>
  )
}
