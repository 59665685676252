import React from 'react';
import './Footer.scss';
import Logo from '../../assets/logo_footer.svg';
import Link from './Link';
import { decryptMailTo } from '../utils/decrypt';

export default class Footer extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.mailIn = React.createRef();
    this.mailHidden = React.createRef();
    this.state = {
      accepted: true,
    };
    this.year = new Date().getFullYear();
  }

  componentDidMount() {
    this.setState({
      accepted: this._getCookieValue('sl_ck_acc'),
    });
  }

  accept() {
    const expiry = new Date();
    expiry.setFullYear(this.year + 3);
    document.cookie = `sl_ck_acc=1; domain=scenelab.io; expires=${expiry.toUTCString()};`;
    this.setState({
      accepted: true,
    });
  }

  _getCookieValue(name) {
    const cookie = document.cookie.match(
      '(^|;)\\s*' + name + '\\s*=\\s*([^;]+)',
    );
    return cookie ? cookie.pop() : '';
  }

  render() {
    const consentClasses = this.state.accepted
      ? 'cookie-consent hidden'
      : 'cookie-consent';
    return (
      <div>
        <div className="social-bar">
          <div className="content-padding">
            <div className="content-container">
              <div className="wrapper">
                <div className="social-connect">
                  <div>
                    <h3>Stay connected</h3>
                    <span>Join and chat with us</span>
                  </div>
                  <div className="social-icons">
                    <Link
                      to="https://www.facebook.com/scenelab.io/"
                      blank={true}
                      aria-label="SceneLab on Facebook"
                    >
                      <i className="icon-facebook" />
                    </Link>
                    <Link
                      to="https://twitter.com/scenelab_io"
                      blank={true}
                      aria-label="SceneLab on Twitter"
                    >
                      <i className="icon-twitter" />
                    </Link>
                    <Link
                      to="https://www.instagram.com/scenelab.io/"
                      blank={true}
                      aria-label="SceneLab on Instagram"
                    >
                      <i className="icon-instagram" />
                    </Link>
                  </div>
                </div>
                <div className="newsletter">
                  <div>
                    <h3>Subscribe to our Newsletter</h3>
                    <span>Don't miss any cool updates & news</span>
                  </div>

                  <form
                    className="newsletter-form validate"
                    action="https://scenelab.us17.list-manage.com/subscribe/post?u=d81c6498e0231667c1e66701d&amp;id=c17987d1ac"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    target="_blank"
                    rel="noopener noreferrer"
                    noValidate
                  >
                    <label htmlFor="mce-EMAIL">E-Mail</label>
                    <div className="newsletter-input-wrapper">
                      <input
                        ref={this.mailIn}
                        type="email"
                        defaultValue=""
                        name="EMAIL"
                        className="email"
                        id="mce-EMAIL"
                        placeholder="email@domain.com"
                        required
                      />
                      <div
                        style={{
                          position: 'absolute',
                          left: '-5000px',
                          ariaHidden: true,
                        }}
                      >
                        <input
                          ref={this.mailHidden}
                          type="text"
                          name="b_d81c6498e0231667c1e66701d_c17987d1ac"
                          tabIndex="-1"
                          defaultValue=""
                        />
                      </div>
                      <div className="clear">
                        <input
                          type="submit"
                          value="Subscribe"
                          name="subscribe"
                          id="mc-embedded-subscribe"
                          className="submit"
                        />
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <footer>
          <div className="content-padding">
            <div className="content-container">
              <div className="logo-area">
                <a
                  className="footer-logo"
                  href="https://scenelab.io#top"
                >
                  <img src={Logo} alt="scenelab-logo" />
                </a>
                <p>
                  SceneLab is a graphics editor providing hundreds of
                  design elements and templates paired with features
                  that allow anyone to create professional mockups and
                  brand design presentations. Get creative and build
                  custom designs or start with editable mockup
                  templates.
                </p>
                <span className="copyright">
                  Copyright © {this.year} SceneLab. All rights
                  reserved.
                </span>
              </div>
              <div className="link-area">
                <ul>
                  <li className="heading">About SceneLab</li>
                  <li>
                    <Link to="/#features">Features</Link>
                  </li>
                  <li>
                    <Link to="/#usecases">Use Cases</Link>
                  </li>
                  <li>
                    <Link to="/#pricing">Pricing</Link>
                  </li>
                  <li>
                    <Link to="/imprint-and-policies">Imprint</Link>
                  </li>
                </ul>
                <ul>
                  <li className="heading">Social</li>
                  <li>
                    <Link
                      to="https://www.facebook.com/scenelab.io/"
                      blank={true}
                    >
                      Facebook
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://twitter.com/scenelab_io"
                      blank={true}
                    >
                      Twitter
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.instagram.com/scenelab.io/"
                      blank={true}
                    >
                      Instagram
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.youtube.com/channel/UCx5CJOCGs5TEVmgXJSu7DIg"
                      blank={true}
                    >
                      Youtube
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li className="heading">Support</li>
                  <li>
                    <Link to="/imprint-and-policies#license">
                      Licensing
                    </Link>
                  </li>
                  <li>
                    <Link to="/imprint-and-policies#terms">
                      Terms & Privacy
                    </Link>
                  </li>
                  <li>
                    <a
                      href="mailto:obfuscated"
                      onClick={(e) => decryptMailTo(e)}
                    >
                      Contact
                    </a>
                  </li>
                </ul>
                <ul>
                  <li className="heading">Quick Links</li>
                  <li>
                    <Link to="https://app.scenelab.io">Open App</Link>
                  </li>
                  <li>
                    <Link to="https://app.scenelab.io/a/login">
                      Signup
                    </Link>
                  </li>
                  <li>
                    <Link to="http://eepurl.com/dj4NkH" blank={true}>
                      Newsletter
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </footer>
        <div className={consentClasses}>
          <div className="content-container">
            <div className="text">
              <span>
                SceneLab uses{' '}
                <Link to="/imprint-and-policies#cookies" blank={true}>
                  cookies
                </Link>{' '}
                to login users and analyze traffic
              </span>
            </div>
            <button id="accept-cookie" onClick={() => this.accept()}>
              I accept
            </button>
          </div>
        </div>
      </div>
    );
  }
}
