import React from 'react';
import {decryptMailTo} from '../utils/decrypt'

const Mail = () => {
  return (
  <a href="mailto:obfuscated" onClick={e => decryptMailTo(e)}>contact(at)scenelab.io</a>
  )
};

export default Mail;
